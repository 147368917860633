import React, { createElement, useState } from "react";
import "../home/styles.css";
import PropertyNotes from "../../assets/Notes.svg";
import "./default.scss";
import logo from "../../assets/logo.svg";

import { Nav } from "../../components/nav/Nav";
import { AboutMe } from "../aboutMe";

export function Home() {
   const [selected, setSelected] = useState("home");
   const [hover, setHover] = useState();

   const handleSelect = (link) => {
      setSelected(link);
   };

   const renderProjectInfo = () => {
      return (
         <div className="projectHover">
            <p className="projectHover__title">REMAX Mobile App</p>
            <div className="projectHover__sub">
               <p className="projectHover__sub--text">think. make. solve.</p>
            </div>
            <p className="projectHover__details">The notes feature enables users, realtors, and co-buyers to leave a quick note on a property.</p>
            <button className="projectHover__button">View Project</button>
         </div>
      );
   };

   const renderPage = () => {
      switch (selected) {
         case "about":
            return <AboutMe />;
         case "home":
         default:
            return (
               <div className="project">
                  <div
                     className={"project__section"}
                     style={!hover ? {} : { border: "2px solid #E4E5FC" }}
                     onMouseEnter={() => setHover(true)}
                     onMouseLeave={() => setHover(false)}>
                     {hover ? renderProjectInfo() : <img className="project__section-image" alt={"remax"} src={PropertyNotes} />}
                  </div>
                  <div className="project__section"></div>
                  <div className="project__section"></div>
                  <div className="project__section"></div>
                  <div className="project__section"></div>
                  <div className="project__section"></div>
               </div>
            );
      }
   };

   const renderMaintenance = () => {
      return (
         <div className="project">
            <div className="header-main">Website in maintenance</div>
         </div>
      );
   };

   return (
      <div className="body">
         <div className="home-container">
            {renderMaintenance()}
            {/*<Nav setRoute={handleSelect} selected={selected} />*/}
            {/*{selected === "home" && (*/}
            {/*   <div className="header">*/}
            {/*      <div className="header-main">Senior product +</div>*/}
            {/*      <div className="header-main-bottom">*/}
            {/*         user interface <span className={"designer"}>designer</span>*/}
            {/*      </div>*/}
            {/*   </div>*/}
            {/*)}*/}
            {/*{renderPage()}*/}
            {/*<footer>*/}
            {/*   <div className="footer-wrapper">*/}
            {/*      <img src={logo} width={30} />*/}
            {/*      <a className="footer-linkedin" href={"https://www.linkedin.com/in/ericagallo/"}>*/}
            {/*         LinkedIn*/}
            {/*      </a>*/}
            {/*   </div>*/}
            {/*   <p className="footer-text">Erica Gallo ©2022 All rights reserved</p>*/}
            {/*</footer>*/}
         </div>
      </div>
   );
}
