import "./App.css";
import { Home } from "./pages/home";

function App() {
   return (
      <div className="body">
         <Home />
      </div>
   );
}

export default App;
